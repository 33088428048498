export const timezone = 'America/New_York'

export const availableLocales = ['en', 'fr'] as const
export const mediafilmEmail = 'info@mediafilm.ca'
export const DEFAULT_HITS_PER_PAGE = 6
export const INFINITE_HITS = 9999999
export const DELAY_MS = 1000
export const BEST_MOVIE_RATING = 7
export const yearNameRegex = /^\/(\d{4})\/(.+)$/
export const confirmButtonClasses = 'p-button-sm p-button-sm !h-[60px] px-4 @sm:!px-16'
export const moviesGridClasses =
  'grid grid-cols-2 @[576px]:grid-cols-3 @md:grid-cols-4 @[1200px]:grid-cols-5  @[1300px]:grid-cols-[repeat(auto-fill,minmax(220px,1fr))] @[1536px]:grid-cols-6 @[1800px]:grid-cols-7 gap-6 justify-between'
export const moviesGridParentClasses =
  ' flex  @lg:!w-[220px] @[1200px]:!w-[200px] @[1250px]:!w-[220px]'
export const moviesGridImgClasses = '!max-w-none !mx-0  [&>img]:!object-cover [&>img]:!w-full'
export const searchInputClasses =
  'flex items-center h-[57px] flex-wrap gap-1 rounded-lg !text-lg !bg-[#050505] focus:!ring-2 focus:!ring-primary-50 !ring-[#050505] !placeholder-[#4B4B4B] text-white/60  py-3 px-6 w-full'
export const galleriaResponsiveOptions = [
  {
    breakpoint: '1670px',
    numVisible: 7
  },
  {
    breakpoint: '1024px',
    numVisible: 5
  },
  {
    breakpoint: '768px',
    numVisible: 3
  },
  {
    breakpoint: '560px',
    numVisible: 2
  }
]

export const excludedChannels = [
  'A Channel',
  'BET',
  'BRAVO',
  'Canal Indigo',
  'Canal Mystère',
  'Canal+ International',
  'CTV Drama Channel',
  'CKMI',
  'Family Channel',
  'FOX',
  'GetTV',
  'M Excess',
  'M Fest',
  'M Fun',
  'Max',
  'Musique Plus',
  'PBS',
  'Shaw',
  'Space',
  'TBS',
  'TMN',
  'TQS',
  'WSBK',
  'Yoopa'
]
